#mediaquery {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 0 (0.75 * $spacer);
    border-radius: 0 (0.5 * $spacer) 0 0;
    color: white;

    &::after {
        content: "XS";

        @include media-breakpoint-up(sm) {
            content: "SM";
        }

        @include media-breakpoint-up(md) {
            content: "MD";
        }

        @include media-breakpoint-up(lg) {
            content: "LG";
        }

        @include media-breakpoint-up(xl) {
            content: "XL";
        }
    }
}
