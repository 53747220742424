$block: "accordion";
.#{$block}{
    &__headline {
        padding: .3125rem .9375rem;
        border-bottom: 1px solid #666;
        margin-bottom: 15px;
        padding-left: 0;
        .btn {
            color: #666;
            font-weight: 500;
            text-align: left;
            margin-bottom: 12px;
            width: 100%;
            padding-left: 0;
            margin-left: 0;
            margin-bottom: 0;
            white-space: normal;
            color: #f18400;
            text-decoration: none;
            .arrow-icon {
                float: right;
                img {
                    color: #f18400;
                    display: block;
                    width: 16px;
                }
            }
            &.collapsed {
                .arrow-icon {
                    -webkit-transform: rotateZ(180deg);
                    transform: rotateZ(180deg);
                }
            }
        }
    }
    ul {
        list-style: none;
        padding-bottom: 0;
        margin-bottom: 10px;
    }
    p {
        margin-bottom: 15px;
    }
    ul li {
        background: none;
        padding-left: 20px;
        padding-bottom: 10px;
        &:before {
            content: "\2022";
        color: #f18400;
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
        }
    }
 }