* {
    @include vendor-prefixes("box-sizing", "border-box");
}

/* ********** globals ************************************************************************** */

body {
    font: 14px Helvetica, Arial, sans-serif;
    color: #666;
}

strong {
    font-weight: bold;
}

a {
    color: #004b78;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

main {
    margin-top: 15px;
}

/* ********** header *************************************************************************** */
header nav {
    padding-top: 10px;
    padding-bottom: 10px;
    display: block;
}

header nav img {
    max-height: 40px;
    max-width: 100%;
}

header nav .language-select {
    height: 100%;
    position: relative;
}

header nav .language-select button {
    border: none;
    background: transparent;
    cursor: pointer;
    height: 100%;
    width: 100%;
    padding: 0;
    display: flex;
    align-items: center;
    margin: 8px 0;
    justify-content: flex-end;
}

header nav .language-select button:focus {
    box-shadow: none;
    outline: none;
}

header nav .language-select button img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

header nav .language-select__dropdown {
    display: none;
    position: absolute;
    z-index: 1;
    background-color: white;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    right: 0;
    width: 100%;
}

header nav .language-select__dropdown a {
    display: block;
    padding: 8px 20px;
    color: inherit;
}

header .banner {
    position: relative;
    max-height: 300px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

header .banner h1 {
    position: absolute;
    top: 0;
    color: white;
    font-size: 1.25em;
    padding-bottom: 1em;
    padding-top: 25px;
}

header .banner img {
    max-width: 100%;
    display: block;
}


/* ********** section ************************************************************************** */
section {
    max-width: 1140px;
}

section div.row {
    padding-bottom: 35px;
}

section h1 {
    color: #666;
    font-size: 1.75em;
    padding-bottom: 1em;
    padding-top: 2em;
}

section h2 {
    color: #666;
    font-size: 1.5em;
    padding-bottom: .5em;
    padding-top: .5em;
}

section h3 {
    color: #666;
    font-size: 1.3em;
    padding-bottom: .5em;
    padding-top: .5em;
}

section p {
    line-height: 1.75em;
    margin-bottom: 1.5em;
}

b {
    font-weight: bold;
    color: #666666;
}

i {
    font-style: italic;
}

u {
    text-decoration: underline;
}

section ul {
    line-height: 1.75em;
    padding-bottom: 1.5em
}

section ul li {
    background: url('../assets/icons/haken.svg');
    padding-left: 50px;
    background-position: 0 2px;
    background-size: 30px 23px;
    background-repeat: no-repeat;
    padding-bottom: 20px;
}

section ol {
    line-height: 1.75em;
    list-style: decimal;
    padding-left: 1.5em;
    padding-bottom: 1.5em
}

section img {
    display: block;
    max-width: 100%;
    width: 100%;
}

section img.left {
    float: left;
    padding: 0 1em 1em 0;
}

section img.right {
    float: right;
    padding: 0 0 1em 1em;
}

section img.small {
    width: 150px;
    height: auto;
}

section img.medium {
    width: 200px;
    height: auto;
}

section img.full-width {
    width: 100%;
}

/* ********** responsive *********************************************************************** */

@media (min-width: 576px) {
    header nav {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    header nav .language-select button {
        width: auto;
        margin: 0;
    }

    header nav .language-select__dropdown {
        width: auto;
        margin-top: 5px;
    }

    header .banner h1 {
        font-size: 1.75em;
    }
}

@media (min-width: 768px) {}

@media (min-width: 992px) {}

@media (min-width: 1200px) {}