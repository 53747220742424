pre {
    &.desert {
        /* desert scheme ported from vim to google prettify */
        &.prettyprint {
            display: block;
            background-color: #333;
        }

        & .nocode {
            background-color: none;
            color: #000;
        }

        & .str {
            color: #ffa0a0;
        }

        /* string  - pink */
        & .kwd {
            color: #f0e68c;
            font-weight: bold;
        }

        & .com {
            color: #87ceeb;
        }

        /* comment - skyblue */
        & .typ {
            color: #98fb98;
        }

        /* type    - lightgreen */
        & .lit {
            color: #cd5c5c;
        }

        /* literal - darkred */
        & .pun {
            color: #fff;
        }

        /* punctuation */
        & .pln {
            color: #fff;
        }

        /* plaintext */
        & .tag {
            color: #f0e68c;
            font-weight: bold;
        }

        /* html/xml tag    - lightyellow */
        & .atn {
            color: #bdb76b;
            font-weight: bold;
        }

        /* attribute name  - khaki */
        & .atv {
            color: #ffa0a0;
        }

        /* attribute value - pink */
        & .dec {
            color: #98fb98;
        }

        /* decimal         - lightgreen */

        /* Specify class=linenums on a & to get line numbering */
        ol.linenums {
            margin-top: 0;
            margin-bottom: 0;
            color: #aeaeae;
        }

        /* IE indents via margin-left */
        li.L0,
        li.L1,
        li.L2,
        li.L3,
        li.L5,
        li.L6,
        li.L7,
        li.L8 {
            list-style-type: none;
        }

        @media print {
            &.prettyprint {
                background-color: none;
            }

            & .str,
            code .str {
                color: #060;
            }

            & .kwd,
            code .kwd {
                color: #006;
                font-weight: bold;
            }

            & .com,
            code .com {
                color: #600;
                font-style: italic;
            }

            & .typ,
            code .typ {
                color: #404;
                font-weight: bold;
            }

            & .lit,
            code .lit {
                color: #044;
            }

            & .pun,
            code .pun {
                color: #440;
            }

            & .pln,
            code .pln {
                color: #000;
            }

            & .tag,
            code .tag {
                color: #006;
                font-weight: bold;
            }

            & .atn,
            code .atn {
                color: #404;
            }

            & .atv,
            code .atv {
                color: #060;
            }
        }
    }

    &.sunburst {
        /*
        Pretty printing styles. Used with prettify.js.
        Vim sunburst theme by David Leibovic
        */

        & .str,
        code .str {
            color: #65b042;
        }

        /* string  - green */
        & .kwd,
        code .kwd {
            color: #e28964;
        }

        /* keyword - dark pink */
        & .com,
        code .com {
            color: #aeaeae;
            font-style: italic;
        }

        /* comment - gray */
        & .typ,
        code .typ {
            color: #89bdff;
        }

        /* type - light blue */
        & .lit,
        code .lit {
            color: #3387cc;
        }

        /* literal - blue */
        & .pun,
        code .pun {
            color: #fff;
        }

        /* punctuation - white */
        & .pln,
        code .pln {
            color: #fff;
        }

        /* plaintext - white */
        & .tag,
        code .tag {
            color: #89bdff;
        }

        /* html/xml tag    - light blue */
        & .atn,
        code .atn {
            color: #bdb76b;
        }

        /* html/xml attribute name  - khaki */
        & .atv,
        code .atv {
            color: #65b042;
        }

        /* html/xml attribute value - green */
        & .dec,
        code .dec {
            color: #3387cc;
        }

        /* decimal - blue */

        &.prettyprint,
        code.prettyprint {
            background-color: #000;
            border-radius: 8px;
        }

        &.prettyprint {
            width: 95%;
            margin: 1em auto;
            padding: 1em 2.5em;
            white-space: pre-wrap;
        }

        /* Specify class=linenums on a & to get line numbering */
        ol.linenums {
            margin-top: 0;
            margin-bottom: 0;
            color: #aeaeae;

            & li {
                list-style-type: decimal !important;
            }
        }

        /* IE indents via margin-left */
        li.L0,
        li.L1,
        li.L2,
        li.L3,
        li.L5,
        li.L6,
        li.L7,
        li.L8 {
            list-style-type: none;
        }

        /* Alternate shading for lines */
        li.L1,
        li.L3,
        li.L5,
        li.L7,
        li.L9 {
            background: black;
        }

        @media print {
            & .str,
            code .str {
                color: #060;
            }

            & .kwd,
            code .kwd {
                color: #006;
                font-weight: bold;
            }

            & .com,
            code .com {
                color: #600;
                font-style: italic;
            }

            & .typ,
            code .typ {
                color: #404;
                font-weight: bold;
            }

            & .lit,
            code .lit {
                color: #044;
            }

            & .pun,
            code .pun {
                color: #440;
            }

            & .pln,
            code .pln {
                color: #000;
            }

            & .tag,
            code .tag {
                color: #006;
                font-weight: bold;
            }

            & .atn,
            code .atn {
                color: #404;
            }

            & .atv,
            code .atv {
                color: #060;
            }
        }
    }
}
