.block {
    &__element {
        &--modifier {
            color: green;
        }
    }

    body {
        color: red;
    }
}
