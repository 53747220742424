.styleguide {
    &-header {
        padding: 2*$spacer 0;

        &__container {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
        }

        &__backbutton {
            margin-right: 2*$spacer;
        }

        &__select {
            height: 100%;
            padding: (0.5 * $spacer) (1.5 * $spacer) (0.5 * $spacer) $spacer;
            margin-right: $spacer;
        }
    }

    &-body {
        &__title {
            margin-bottom: 1.5*$spacer;
        }

        &__example {
            &-item {
                margin: 1.5*$spacer 0;
                transition: transform 0.4s;

                &:hover {
                    transform: scale(1.0125);
                }
            }
        }
    }
}
