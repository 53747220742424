$block: "footer";

.#{$block} {
    color: white;
    background: #004b78;
    font-size: 12px;

    span {
        padding: 20px 15px;
        display: block;
    }

    a {
        color: white;
        padding: 20px 15px;
        display: inline-block;
        outline: 0;
    }
}

@media (min-width: 992px) {
    .#{$block} {
        span {
            display: inline-block;
            padding-right: 30px;
        }
    }
}