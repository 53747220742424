$block: "modal";
.#{$block}{ 
    .modal-header {
        button {
            margin-bottom: -2rem;
            background-color: transparent;
            border: 0;
            cursor: pointer;
            outline: 0;
            span {
                font-size: 24px;
            }
        }
    }
    ul {
        li {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            i.fa.fa-file-pdf {
                color: #E50305;
            }
        }
    }
} 
